import { Tabs } from "antd";
import { useState } from "react";
import ConsignmentsView from "./consignments.view";

export default function ExpandedView({ record, expanded, selectedTags, selectedCustomers }) {

    const [ tabKey, SetTabKey ] = useState("shipments");

    return (
        <>
            {
                location.pathname.indexOf("lm-ops") > -1 &&
                <Tabs className="log10-tabs trip-status-modal-tabs" defaultActiveKey="shipments" activeKey={tabKey} onChange={SetTabKey}>
                    <Tabs.TabPane tab={<span className="text-semi-bold">Shipment List</span>} key="shipments" />
                    <Tabs.TabPane tab={<span className="text-semi-bold">Map View</span>} key="map" />
                </Tabs>
            }
            {
                tabKey === "shipments" ?
                <ConsignmentsView drsId={record.drsId} expanded={expanded} selectedCustomers={selectedCustomers}
                 selectedTags={selectedTags} feId={record.feId} /> :
                <iframe id="embedIframe" height={750} src={`https://feature-log10-embed-view--staffpay-web.netlify.app/3p-embed-view?userContact=8939056025&url=/embed-map-view/detail/${record?.staffpayUserId}&org=log10`} frameBorder={0} width="100%" />
            }
        </>
    )
}