import { useLayoutEffect, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Modal, Select, Divider, Button, Row, Col, Skeleton, Spin, List, Radio, Timeline, DatePicker, Icon } from "antd";
import NumberFormat from "react-number-format";
import { fetchSuspiciousWaybillData, validateSuspicious, sendOTP, validateOtp } from "../service";
import { initiateConsigneeCall } from "../../../TrackingPopup/action";
import { checkHttpStatus, notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "../components.scss"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as moment from "moment";
import OTPInput from "../../../common/otp-input/otp-input";
import { destroyNotification, openNotification } from "../../../ui/notification/Notification";
import { SUSPICIOUS_REASONS_MAP } from "./transform.data";


export default function SuspiciousConsignmentAction({drsCode, waybillNo, onClose}) {
    const [data, SetData] = useState(null);
    const [scrollData, setScrollData] = useState([]);
    const [prevPodData, setPrevPodData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [mapCenter, SetMapCenter] = useState({ lat: 31.520001, lng: 78.2000400 });
    const [mapZoom, SetMapZoom] = useState(18);
    const [callToCustomerMade, SetCallToCustomerMade] = useState(false);
    const [enableResolveStatusSubmit, SetEnableResolveStatusSubmit] = useState(false);
    const [isResolved, SetIsResolved] = useState(false);
    const [resolveStatusCase, SetResolveStatusCase] = useState('');
    const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
    const [isPrimaryModalVisible, setIsPrimaryModalVisible] = useState(true);
    const [selectedValue, setSelectedValue] = useState('CNR');
    const [otpInput, setOtpInput] = useState('');
    const [rescheduleDate, SetRescheduleDate] = useState(moment());
    const [suspiciousReasonsStatus, setSuspiciousReasonsStatus] = useState(null);
    const [otpValidation, SetOtpValidation] = useState(false);
    const [loading, SetLoading] = useState(false);
    const [sendOtpTimeout, SetSendOtpTimeout] = useState(false);

    const appConfig = useSelector(state => state?.app?.configurations);
    const rejectByOtpEnabledLoc = appConfig?.reject_by_otp_enabled_loc || [];

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });

    useEffect(() => {
        SetLoading(true);
        const params = {
            drsCode: drsCode,
            waybillNo: [waybillNo]
        }
        fetchSuspiciousWaybillData(params).then(response => {
            SetData(response);
            formatScrollableData(response);
            SetLoading(false);
        }
        ).catch(
            SetLoading(false)
        )
    }, [])

    /**
     * 
     * scrollData
     * - code - 1,2,3,4
     * - flag - prevPOD, commDetails
     * - data1 - data.fieldName
     * - data2 - data.fieldName
     * - data3 - data.fieldName
     * 
     */
    const formatScrollableData = (podData) => {
        let markerData = [];
        let listItems = [];
        let prevDataItems = [];
        let count = 1;
        let attemptCount = 1;

        if (podData.previousPODs && podData.previousPODs.length > 0) {

            podData.previousPODs.forEach(element => {
                let date = moment(new Date(element?.undelTime)).format('DD MMM YYYY');
                let listData = {
                    code: attemptCount,
                    flag: "prevPOD",
                    time: date,
                    riderName: element?.riderName,
                    suspiciousReason: element.suspiciousReason,
                    undelReason: element.undelReason
                }
                prevDataItems.push(listData);
                attemptCount++;
            });
        }
        let undelAdded = false;
        let undelMarker = {};
        if (podData.callDetails && podData.callDetails.length > 0) {

            podData.callDetails.forEach(element => {
                if (podData?.currentPOD?.undelTime < element.commTime) {
                    let undelData = {
                        code: count,
                        time: moment(new Date(podData?.currentPOD?.undelTime)).format("hh:mm A"),
                        flag: "undelEvent",
                        undelReason: podData?.currentPOD?.undelReason,
                        undelLatitude: podData?.currentPOD?.undelLatitude,
                        undelLongitude: podData?.currentPOD?.undelLongitude
                    }
                    listItems.push(undelData);
                    count++;
                    undelAdded = true;

                    if (podData?.currentPOD?.undelLatitude && podData?.currentPOD?.undelLongitude) {
                        undelMarker = {
                            index: "U",
                            lat: podData?.currentPOD?.undelLatitude,
                            lng: podData?.currentPOD?.undelLongitude,
                            code : undelData.code,
                            zIndexOffset : 1,
                            description: "Rider marked undel", // Optional
                        };
                        markerData.push(undelMarker);
                    }
                }
                let date = moment(new Date(element?.commTime)).format("hh:mm A");
                if (element.commLatitude != null && element.commLongitude != null) {
                    const marker = {
                        index: count,
                        lat: element.commLatitude,
                        lng: element.commLongitude,
                        code : count,
                        zIndexOffset : 1,
                        description: "Communication at " + date, // Optional
                    };
                    markerData.push(marker);
                }
                let callStatus = false;
                let callDescription = '';
                let isHiglighted = false;
                // Call status and description
                if (element.callType === 'fe_cons_route') {
                    if (element?.leg1Status === 'failed') {
                        callDescription = "Call failed"
                    }
                    else if (element?.leg2status === 'failed') {
                        callDescription = "Customer unreachable"
                    }
                    else if (element?.leg2status === 'canceled' && element.duration < 30) {
                        callDescription = "Call cut prematurely"
                        isHiglighted = true;
                    }
                    else if ((element?.leg2status === 'canceled' && element.duration > 30) || element?.leg2status === 'no-answer') {
                        callDescription = "Customer did not pickup"
                    }
                    else if (element?.leg1Status === 'completed' && element?.leg2status === 'completed') {
                        callDescription = "Call completed successfully"
                    }
                }
                else if (element.callType === 'branch_cons') {
                    callDescription = "Hub attempted to call customer";
                    SetCallToCustomerMade(true)
                }
                else if (element.callType === 'cons_fe_route') {
                    callDescription = "Customer attempted to call rider";
                }
                else if (element.callType == 'fe_cons_direct') {
                    callDescription = 'Called connected via customer number.'
                }

                let listData = {
                    code: count,
                    flag: "callDetails",
                    time: date,
                    callUrl: element?.callRecording,
                    callType: element.callType ? element.callType : 'cons_fe_wa',
                    callStatus: element?.callRecording && "" != element?.callRecording ? true : false,
                    callDescription: callDescription,
                    isHiglighted: isHiglighted,
                    commLatitude: element.commLatitude,
                    commLongitude: element.commLongitude
                }

                listItems.push(listData);
                count++;
            });
        }
        if (!undelAdded) {
            let undelData = {
                code: count,
                time: moment(new Date(podData?.currentPOD?.undelTime)).format("hh:mm A"),
                flag: "undelEvent",
                undelReason: podData?.currentPOD?.undelReason,
                undelLatitude: podData?.currentPOD?.undelLatitude,
                undelLongitude: podData?.currentPOD?.undelLongitude
            }
            listItems.push(undelData);
            count++;
            if (podData?.currentPOD?.undelLatitude && podData?.currentPOD?.undelLongitude) {
                undelMarker = {
                    index: podData?.currentPOD?.shipmentStatus == "DEL" ? "D" : "U", // Optional,
                    lat: podData?.currentPOD?.undelLatitude,
                    lng: podData?.currentPOD?.undelLongitude,
                    code : undelData.count,
                    zIndexOffset : 1,
                    description: podData?.currentPOD?.shipmentStatus == "DEL" ? "Rider marked Del" : "Rider marked Undel", // Optional
                };
                markerData.push(undelMarker);
            }
        }
        const customerMarker = {
            index: "C",
            lat: podData?.currentPOD?.customerLatitude,
            lng: podData?.currentPOD?.customerLongitude,
            code : count - 1,
            zIndexOffset : 1,
            description: "Customer location", // Optional
        };

        markerData.push(customerMarker);
        
        setPrevPodData(prevDataItems);
        setScrollData(listItems);
        setMarkers(markerData);

        if (!(undelMarker?.lat || undelMarker?.lng)) {
            if(mapCenter.lat===null)
                SetMapCenter(markerData[0]);
        }
        else {
            SetMapCenter(undelMarker);
        }
    }
    
    const closePrimaryModal = _ => {
        if (enableResolveStatusSubmit) {
            setIsPrimaryModalVisible(false);
            onClose(true);
        }
        else if (!data?.currentPOD?.suspiciousReasonDesc) {
            setIsPrimaryModalVisible(false);
            onClose(true);
        }
        else {
            Modal.confirm(
                {
                    title: "Resolve Suspicious POD",
                    content:
                        <h4 >
                            Close without resolving suspicious POD?
                        </h4>,
                    zIndex: 1001,
                    onOk: () => {
                        closeWithoutResolving();
                        return Promise.resolve();
                    },
                    onCancel: () => {
                        // do nothing, continue reolving
                    },
                    cancelText: 'Continue Resolving'
                }
            )
        }
    }
    const closeWithoutResolving = _ => {
        setIsPrimaryModalVisible(false);
        onClose(false);
    }
    
    const resolveSuspiciousPOD = _ => {
        const params = {
            status: suspiciousReasonsStatus !== "ACCEPT" ? "REJECTED" : "ACCEPTED",
            reason: data?.currentPOD?.undelReason,
            drsCode: drsCode,
            waybillNo: waybillNo,
            rescheduleDate: rescheduleDate
        };
        validateSuspicious(params).then(_ => {
            notifyApiSuccess("Succcesfuly updated POD")
            setIsSecondModalVisible(false);
        })
    }
    const resolveStatus = _ => {
        if (callToCustomerMade) {

            setIsSecondModalVisible(true)
        }
        else {
            Modal.warn({
                title: "Resolve Suspicious POD",
                content:
                    <h4 >
                        Call Customer before resolving Suspicious POD
                    </h4>,
                zIndex: 1001
            })

        }

    }
    const closeResolveStatus = _ => {
        SetIsResolved(true); 
        if (!suspiciousReasonsStatus) {
            notifyApiError("Choose ")
        }
        if (isResolved) {
            resolveSuspiciousPOD();
            SetEnableResolveStatusSubmit(true);
        }
    }

    const handleChange = e => {
        var _selectedValue = e.target.value
        setSelectedValue(_selectedValue);
        SetResolveStatusCase(_selectedValue);
        if (_selectedValue == 'reschedule') {
            setSuspiciousReasonsStatus("REJECT");
        }
        else {
            setSuspiciousReasonsStatus("ACCEPT");
        }
    };

    const sendOtp = _ => {
        SetSendOtpTimeout(true);
        const payload = {
            eventType: "CANCEL",
            consignmentId: 87623733,
            entityCode: drsCode,
            entityType: "DRS",
            entityId: 1458789,
        }
        SetLoading(true);
        sendOTP(payload).then(r => {
            SetOtpValidation(true);
            SetLoading(false);
            setTimeout(() => SetSendOtpTimeout(false), 50000);
        })
    };

    const receivedOtp = otp => {
        SetLoading(true);
        const payload = {
            eventType: "HANDOVER",
            consignmentId: data?.currentPOD?.consignmentId,
            entityCode: drsCode,
            entityType: "DRS",
            entityId: data?.currentPOD.drsId,
            otp: otp
        }
        validateOtp(payload).then(resp => {
            if (resp?.valid) {
                // SetEnableResolveStatusSubmit(true);
                setSuspiciousReasonsStatus("REJECT");
                SetIsResolved(true);
                SetResolveStatusCase('');
                SetLoading(false);
            }
            else {
                SetLoading(false);
            }
        }).catch(e => {
            // notifyApiError(e);
            SetLoading(false);
        })
    };

    const setOtp = e => setOtpInput(e)

    const changeMapCenter = (param, code) => {
        if (param.lat && param.lng) {
            SetMapZoom(18);
            setMarkers(m => {
                m.map(x => {
                    console.log(x, code);
                    if (x.code == code) {
                        x.zIndexOffset = 10
                    }
                    else {
                        x.zIndexOffset = 1
                    }
                });
                return m;
            })
            SetMapCenter(param);
        }
    }

    const setRescheduleDate = date => {
        SetRescheduleDate(date);
    }

    const customMarkerIcon = (content) => {
        return L.divIcon({
            className: 'custom-div-icon',
            html: `<div class="custom-marker">${content}</div>`,
            iconSize: [40, 40],  // size of the div
            iconAnchor: [20, 40],  // point of the icon which will correspond to marker's location
        });
    };

    const disableDate = (current) => {
        const today = new Date();
        const threeDaysLater = new Date();
        threeDaysLater.setDate(today.getDate() + 3); // Set to 3 days later

        // Disable dates before today or after three days later
        return current < today.setHours(0, 0, 0, 0) || current > threeDaysLater.setHours(0, 0, 0, 0);
    };

    const renderContent = () => {
        switch (selectedValue) {
            case 'accept':

                return <div className="resolveOptions flex-column flex-gap-l">
                    <div className="font-size-l-1 text-semi-bold">Accept Suspicious Undelivery</div>
                    <div>
                        Are you sure the rider was not able to deliver the shipment because "<strong>{data?.currentPOD?.undelReason}</strong>"?
                    </div>
                </div>;
            case 'reschedule':
                return <div className="resolveOptions flex-column flex-gap-l">
                    <div className="font-size-l-1 text-semi-bold">Re-attempt shipment</div>
                    <div>Choose re-attempt date</div>
                    <div>
                        <DatePicker format="DD/MM/YYYY"
                            onChange={setRescheduleDate}
                            value={rescheduleDate} getPopupContainer={trigger => trigger.parentNode} disabledDate={disableDate} />
                    </div>

                </div>;
            case 'reject':
                return <div className="resolveOptions flex-column flex-gap-l">
                    {loading ? <Spin spinning={loading}></Spin> : <div>
                        
                        {
                            rejectByOtpEnabledLoc.includes(String(data?.currentPOD?.shipmentLocId ?? "")) ? 
                            <div>
                                <b>Customer rejected shipment</b>
                                <h3>Send OTP for rejecting shipment</h3>
                                <div style={{ display: otpValidation ? 'block' : 'none' }}>
                                    {/* <NumberFormat placeholder="Enter OTP Received" format="####"
                            value={otpInput} onValueChange={valueObj => { setOtp(valueObj.value) }}
                            className="ant-input" style={{ width: '200px' }} /> */}
                                    <OTPInput
                                        inputStyle="inputStyle"
                                        numInputs={4}
                                        isDisabled={false}
                                        hasErrored={false}
                                        errorStyle="error"
                                        onChange={setOtp}
                                        isInputNum={true}
                                        shouldAutoFocus
                                        value={otpInput}
                                    />
                                </div>
                                <div className="flex flex-row">
                                    <Button onClick={sendOtp} className="lsn-btn-secondary flex-1" disabled={sendOtpTimeout}>
                                        Send/Resend OTP
                                    </Button>

                                    <Button onClick={() => receivedOtp(otpInput)} className="lsn-btn-secondary" style={{ display: otpValidation ? 'block' : 'none' }}>
                                        Submit
                                    </Button>
                                </div>
                            </div>:
                            <div>
                                <div className="font-size-l-1 text-semi-bold">
                                    Reject Suspicious Undelivery
                                </div>
                                <b>
                                    Reason: {data?.currentPOD?.undelReason}
                                </b>
                            </div> 
                        }
                    </div>
                    }

                </div>;
            default:
                return <div className="resolveOptions">
                    <b>Please select an option</b>
                </div>;
        }
    };

    return (
        <>
            {
                loading ? 
                <Spin spinning={loading}></Spin> : 
                <Modal title="UNDEL Validation" visible={isPrimaryModalVisible} onCancel={closePrimaryModal} 
                    footer={[ <Button key="close" onClick={closePrimaryModal}> Close </Button> ]} 
                    width="1200px" maskClosable={false} centered={true}>
                    <div className="flex-column flex-gap-xl">
                    <div className="flex-row flex-gap-l">
                        <div className="flex-1">
                            <div className="grey-text font-size-m-1 padding-bottom-10"> Waybill Number</div>
                            <div className="text-semi-bold font-size-m-2"> {waybillNo} </div>
                        </div >
                        <div className="flex-1">
                            <div className="grey-text font-size-m-1 padding-bottom-10"> Location Name</div>
                            <div className="text-semi-bold font-size-m-2"> {data?.currentPOD?.userLocation} </div>
                        </div >
                        
                        <div> <Divider type="vertical" style={{ height: '100%' }}></Divider> </div>

                        <div className="flex-1">
                            <div className="grey-text font-size-m-1 padding-bottom-10"> Attempt Count</div>
                            <div className="text-semi-bold font-size-m-2"> {data?.currentPOD?.attemptedCount} </div>
                        </div >
                        <div className="flex-1">
                            <div className="grey-text font-size-m-1 padding-bottom-10"> Customer Info</div>
                            <div className="flex-box flex-gap-m align-items-center text-semi-bold font-size-m-2"> 
                                {data?.currentPOD?.customerName} 
                                {
                                    data?.currentPOD?.customerLatitude && data?.currentPOD?.customerLongitude &&
                                    <Icon type="environment" theme="filled"
                                     className="success-color" onClick={(() => changeMapCenter({ lat: data?.currentPOD?.customerLatitude, lng: data?.currentPOD?.customerLongitude }))}/>
                                }
                            </div>
                        </div >

                        {/* {* Move this to a sepearate function */}
                        <div className="flex">
                            <Button onClick={e => {
                                const buttonElem = e.target;
                                buttonElem.disabled = true;
                                const ogInnerHTML = buttonElem.innerHTML;
                                buttonElem.innerHTML = "Call again in 30s";
                                const resetButton = () => {
                                    buttonElem.disabled = false;
                                    buttonElem.innerHTML = ogInnerHTML;
                                };
                                initiateConsigneeCall(waybillNo, "PRIMARY")
                                    .then(response => {
                                        if (checkHttpStatus(response?.data?.status)) {
                                            notifyApiSuccess("Call initiated successfully", waybillNo);
                                            setTimeout(resetButton, 30000);
                                            SetCallToCustomerMade(true);
                                        }
                                        else {
                                            resetButton();
                                        }
                                    })
                                    .catch(e => resetButton(e));
                            }} icon="phone" className="lsn-btn-primary align-self-flex-end">
                                Call Customer
                            </Button>
                        </div>

                    </div>

                    {
                        data?.currentPOD?.suspiciousReasonDesc ?
                        <div className="flex-row align-items-center justify-content-space-between higlightedData spacer-m">
                            <div className="flex-row align-items-stretch">
                                <div className="flex-column justify-content-center flex-2">
                                    <div className="grey-text font-size-m-1"> Rider Details</div>
                                    <div className="text-semi-bold font-size-m-2"> {data?.currentPOD?.riderName} ({data?.currentPOD?.riderContact}) </div>
                                </div>
                                <div className="flex-column justify-content-center flex-2">
                                    <div className="grey-text font-size-m-1"> POD created at:</div>
                                    <div className="text-semi-bold font-size-m-2"> {moment(new Date(data?.currentPOD?.undelTime)).format("DD/MM/YYYY hh:mm A")} </div>
                                </div>
                                <div>
                                    <Divider type="vertical" style={{ backgroundColor: "red", height: "100%" }} />
                                </div >
                                {
                                    data?.currentPOD?.suspiciousReasonDesc &&
                                    <div className="flex-3 flex-row align-items-center flex-gap-l" style={{ color: enableResolveStatusSubmit ? 'a5a7af' : 'red' }}>
                                        <Icon type="warning" theme="filled" style={{ color: 'red', fontSize: "25px" }} />
                                        <div className="flex-column">
                                        <div className="font-size-m-1"> Suspicious reason:</div>
                                        <div className="text-semi-bold font-size-m-2"> {data?.currentPOD?.suspiciousReasonDesc} </div>
                                        </div>
                                    </div >
                                }
                            </div>
                            {
                                data?.currentPOD?.suspiciousReasonDesc ?
                                    enableResolveStatusSubmit ? (
                                        <div>
                                            {resolveStatusCase === 'reject' ? (
                                                <div className="text-align-right"><b>Customer Rejected Shipment</b></div>
                                            ) : resolveStatusCase === 'reschedule' ? (
                                                <div className="text-align-right"><b>Re-attempt shipment on {rescheduleDate?.format('DD MMM YYYY')}</b></div>
                                            ) : (
                                                <div className="text-align-right"><b >Undel Reason Validated</b></div>
                                            )}
                                        </div>
                                    ) : (
                                        <Button type="danger" onClick={resolveStatus}
                                        >
                                            Resolve Status
                                        </Button>
                                    ):
                                <></>}
                        </div> :
                        <div className="flex-row align-items-stretch">
                            <div className="flex-column justify-content-center flex-1">
                                <div className="grey-text font-size-m-1"> Rider Details</div>
                                <div className="text-semi-bold font-size-m-2"> {data?.currentPOD?.riderName} ({data?.currentPOD?.riderContact}) </div>
                            </div>
                            <div className="flex-column justify-content-center flex-1">
                                <div className="grey-text font-size-m-1"> POD created at:</div>
                                <div className="text-semi-bold font-size-m-2"> {moment(new Date(data?.currentPOD?.undelTime)).format("DD/MM/YYYY hh:mm A")} </div>
                            </div>
                            <div className="flex-1"></div>
                            <div className="flex-1"></div>
                            <div className="flex-1"></div>
                        </div>
                    }

                    <div className="scroll-and-map" >
                        <div className="scroll">
                            <h3><u>Delivery Timeline</u></h3>
                            <div style={{ overflow: "scroll", height: '90%' }}>

                                {prevPodData.map((item) => (
                                    <div className="flex-box flex-gap-m" style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                                        <div className="flex-1">{item.time}</div>
                                        <div className="flex-2 grey-text">
                                            <i> Attempt <strong> {item.code} </strong>  </i> -- '{item.riderName}'
                                        </div>
                                        <div className="flex-3 flex-column flex-gap-s" >
                                        {
                                            item.suspiciousReason &&
                                            <div style={{ color: "red" }}> 
                                                <strong>Suspicious Undel:</strong> {SUSPICIOUS_REASONS_MAP[item.suspiciousReason] || item.suspiciousReason }
                                            </div> 
                                        }
                                            <div> Undel Reason: {item.undelReason}</div>
                                        </div>
                                    </div>
                                ))}

                                <Divider type="horizontal" />

                                <div className="text-bold" style={{ margin: "-16px 0px"}}> 
                                    {moment(data?.currentPOD?.undelTime).format("DD MMM YYYY")} --- Assigned to '{data?.currentPOD?.riderName}'
                                </div>
                                <Timeline className="timeLineMargin">
                                    {scrollData.map((item) => (
                                        item.flag === "callDetails" ?
                                        <Timeline.Item className="scroll-element" key={item.code} dot={<Button className="custom-dot" onClick={(() => changeMapCenter({ lat: item?.commLatitude, lng: item?.commLongitude }, item.code))}> {item.code}</Button>} style={{ position: 'relative', marginBottom: '10px' }} >
                                            <p className="timeLineTime">{item.time}</p>
                                            <div className="flex flex-gap-s align-items-center timeLineELement">
                                                {(() => {
                                                    if (item.callType === "fe_cons_route" || item.callType === "fe_cons_direct") {
                                                        return <span>Customer <Icon type="phone" theme="filled" style={{ fill: "#ffffff" }} /> Rider | </span>;
                                                    } else if (item.callType === "cons_fe_route") {
                                                        return <span>Rider <Icon type="phone" theme="filled" style={{ fill: "#ffffff" }} /> Customer | </span>;
                                                    } else if (item.callType === "branch_cons") {
                                                        return <span>Branch <Icon type="phone" theme="filled" style={{ fill: "#ffffff" }} /> Customer | </span>;
                                                    } else if (item.callType === "cons_fe_wa") {
                                                        return <span>Customer <Icon type="phone" theme="filled" style={{ fill: "#ffffff" }} /> Rider | </span>;
                                                    }
                                                })()}
                                                <strong> 
                                                    {
                                                        (item?.callType === "cons_fe_wa") ? 
                                                        <a href={`https://www.google.com/maps?q=${item.commLatitude},${item.commLongitude}`} target="_blank"> Shared location</a> : 
                                                        item.callStatus ? 
                                                        <audio src={item.callUrl} type="audio/mpeg"
                                                         controls style={{ width: "260px", height: "15px", marginLeft: "4px" }}
                                                          className="flex flex-end"> No audio</audio> : 
                                                        item?.callDescription
                                                    }
                                                </strong>
                                            </div>
                                        </Timeline.Item> :
                                        <Timeline.Item className="scroll-element" key={item.code} dot={<Button className="custom-dot" onClick={(() => changeMapCenter({ lat: item?.undelLatitude, lng: item?.undelLongitude }, item.code))}> {item.code}</Button>} style={{ position: 'relative', marginBottom: '10px' }}>
                                            <p className="timeLineTime">{item.time}</p>
                                            <div className="flex align-items-center timeLineELement" >
                                                {
                                                    data?.currentPOD?.shipmentStatus == 'DEL' ? 
                                                    <div className="success-color">Marked Delivered</div> :
                                                    <div className="error-color">Marked Undelivered --- <i>{item.undelReason}</i></div>
                                                }
                                            </div>
                                        </Timeline.Item>
                                    ))}
                                </Timeline>

                            </div>
                        </div >
                        <div className="map">
                            <MapContainer center={[mapCenter.lat, mapCenter.lng]} zoom={mapZoom} scrollWheelZoom={false} key={[mapCenter.lat, mapCenter.lng]}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {markers.map((marker, index) => (
                                    (marker.lat&&marker.lng)?<Marker key={index} position={[marker.lat, marker.lng]}
                                     icon={customMarkerIcon(marker.index)} zIndexOffset={marker.zIndexOffset || index}>
                                        <Popup>{marker.description || "No description"}</Popup>
                                    </Marker>:<></>
                                ))}
                            </MapContainer>

                        </div >

                    </div>
                    </div>

                </Modal>
            }
            <Modal title="Resolve Suspicious POD" visible={isSecondModalVisible} onOk={closeResolveStatus} maskClosable={false}
                okText={"Submit"}
                onCancel={_ => {
                    setSelectedValue('');
                    setSuspiciousReasonsStatus('');
                    setIsSecondModalVisible(false);
                    destroyNotification();
                    openNotification({
                        message : "Suspicious POD Not Resolved",
                        warning : true
                    })
                }} zIndex={1001} width={720} className="resolve-status-popup" bodyStyle={{ height: 280 }}>
                <div style={{ height: "100%" }}>
                    <Radio.Group className="flex flex-row" onChange={handleChange} value={selectedValue}>
                        <Radio value="accept">{data?.currentPOD?.undelReason}</Radio>
                        <Radio value="reschedule">Re-attempt shipment</Radio>
                        <Radio value="reject">Customer Rejected shipment</Radio>
                    </Radio.Group>

                    {isResolved ?
                        <div style={{ width: "Auto", paddingTop: "24px" }}>
                            Click on Submit
                        </div> : <div style={{ width: "Auto", paddingTop: "24px" }}>
                            {renderContent()}
                        </div>}
                </div>
            </Modal>
        </>
    )
}