import { Alert, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { notifyApiError } from "../../../../common/utils/utils";
import { validateSuspicious } from "../service";
import { SUSPICIOUS_REASONS_MAP } from "./transform.data";
import SuspiciousConsignmentAction from "./suspicious.consignment.action";

export default function ValidateRejectModal({ validateModalType, SetValidateModalType, onSuccess, validateWaybillDetails, enhancedResolvePopup }) {
    
    const [ selctedReason, SetSelctedReason ] = useState(undefined);

    const appConfig = JSON.parse(atob(localStorage.getItem("_configrations")));
    const { reasons } = appConfig;

    const callValidateSuspicious = _ => {

        var value = undefined;
        if(validateModalType == "REJECT") {
            if (selctedReason === undefined) {
                return notifyApiError("Select a valid reason to reject the Undelivery!");
            }
            else if (selctedReason === "OTHER") {
                value = document.getElementById("otherReasons")?.value;
                if (!value || value.trim() == "") {
                    return notifyApiError("Enter a valid reason to reject the Undelivery!");
                }
            }
        }

        var reason = '';
        if(selctedReason === "OTHER"){
            reason = value;
        }
        else if (Array.isArray(reasons?.UNDELIVERED)) {
            reason = reasons?.UNDELIVERED.find(r => r.reasonId == selctedReason)?.reasonDescription
        }

        const postObj = {
            status       : validateModalType !== "ACCEPT" ? "REJECTED" : "ACCEPTED",
            reason       : reason,
            drsCode      : validateWaybillDetails?.entityCode,
            waybillNo    : validateWaybillDetails?.waybillNo
        };

        validateSuspicious(postObj)
        .then(r => {
            SetSelctedReason(undefined);
            SetValidateModalType(false);
            onSuccess(r);
        })
    }

    const closeModal = _ => {
        SetValidateModalType(false);
        SetSelctedReason(undefined);
    }

    if (enhancedResolvePopup) {
        return (
            validateModalType &&
            <SuspiciousConsignmentAction drsCode = {validateWaybillDetails?.entityCode} waybillNo = {validateWaybillDetails?.waybillNo}
             onClose={status => {
                SetSelctedReason(undefined);
                SetValidateModalType(false);
                if (status) {
                    onSuccess();
                }
             }} />
        )
    }
    else {
        return (
            <>
                <Modal title="Accept Suspicious Undelivery"
                 visible={validateModalType == "ACCEPT"}
                 onCancel={closeModal} onOk={callValidateSuspicious}
                 okText={"Accept Undel"} okButtonProps={{ className : "lsn-btn-success", type : "default" }} >
                    <div className="flex-column flex-gap-l">
                        <Alert message={'Please validate all Rider actions before accepting Undel.'} showIcon={true}
                         type="info"/>
                        <div>
                            Are you sure the rider was not able to deliver the shipment because "<strong>{validateWaybillDetails?.subStatus}</strong>"?
                        </div>
                    </div>
                </Modal>
    
                <Modal title="Reject Suspicious Undelivery"
                 visible={validateModalType == "REJECT"}
                 onCancel={closeModal}  onOk={callValidateSuspicious}
                 okText={"Reattempt Shipment"} okButtonProps={{ className : "lsn-btn-error", type : "default" }} >
                    <div className="flex-column flex-gap-xl spacer-m">
                        <Alert message={'Please validate all Rider actions before rejecting Undel.'} showIcon={true}
                         type="info"/>
                        <div>
                            Suspicious Reaons : "<strong>{(SUSPICIOUS_REASONS_MAP[validateWaybillDetails?.suspiciousReason] || validateWaybillDetails?.suspiciousReason)}</strong>"
                        </div>
                        <div>
                            Are you sure rider did not attempt correctly and should Reattempt the shipment?
                        </div>
                        <Select style={{ width : '100%' }} placeholder={"Select Valid Reason"} onChange={SetSelctedReason} value={selctedReason}>
                            {
                                reasons?.UNDELIVERED?.map(reason => (
                                    <Select.Option value={reason.reasonId} key={reason.reasonId}>
                                        {reason.reasonDescription}
                                    </Select.Option>
                                ))
                            }
                            <Select.Option value={"OTHER"} key={"OTHER"}> Other </Select.Option>
                        </Select>
                        {
                            selctedReason == "OTHER" &&
                            <TextArea id="otherReasons" placeholder="Enter a valid reason" />
                        }
                    </div>
                </Modal>
            </>
        )
    }
}