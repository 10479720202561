import { Modal, Spin } from "antd";
import React, { useState, useRef, useCallback } from "react";
import FormWidgets from "../../ui/formComponent/formWidgets";
import { triggerBatchingApi, updateBatchingStatus } from "./service";
import { Constants } from "../../../common/utils/constants";
import FormWrapper from "../../ui/formComponent/form";
import './drs.scss';
import { connect, useSelector } from "react-redux";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import debounce from "lodash/debounce";
import TextArea from "antd/lib/input/TextArea";

const BatchingModal = ({ openModal, closeModal, updateBatchingStatus }) => {

    const schema = {
        "type": "object",
        "properties": {
            "excludePincodes": {
                "title": "Exclude Pincodes",
                "type": "string"
            }
        }
    }

    const uiSchema = {
        "excludePincodes": {
            "ui:widget": "textarea",
            "ui:placeholder": "Enter pincodes to be excluded (comma separated)",
            "classNames": "batching-field-exclude-pincode"
        }
    }

    const errorList = {
    }

    const formRef = useRef(null);
    const config = useSelector(state => state.app.configurations);
    const [formData, setFormData] = useState({})
    const [loadingData, setLoadingData] = useState(false);

    const handleCancel = () => {
        closeModal()
    }

    const validate = (formData, errors) => {

        const excludePincodeRegex = /^(\d{6}(,\d{6})+|\d{6})$/;

        if (formData?.excludePincodes && !excludePincodeRegex.test(formData.excludePincodes)) {
            errors.excludePincodes.addError("Please enter pincodes in valid format. Eg:- 123456,123456")
        }

        return errors;

    }

    const handleSubmit = (formData) => {

        setLoadingData(true)

        Modal.confirm({
            title: 'Are you sure?',
            content: `You want to start batching ${formData?.excludePincodes ? "excluding pincodes " + formData.excludePincodes : ""}`,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                handleSubmitDebounce(formData)
            },
            onCancel() {
                setLoadingData(false)
            },
        })

    }

    const handleOnModalConfirmation = (formData) => {

        updateBatchingStatus("Batching in progress...")

        const queryParams = {}
        var manifestCodesInputArray = [];

        queryParams["locationId"] = Constants.userLocationId
        queryParams["batchStartTime"] = Date.now() + (10 * 60 * 1000)

        if (formData?.excludePincodes) {
            queryParams["zipcode"] = formData.excludePincodes
        }

        if (Constants.userLocationId == '5880292') {
            queryParams["zipcode"] = '560027,560047,560030';
        }
        else if (Constants.userLocationId == '5880294') {
            queryParams["zipcode"] = '560100';
        }

        if (formData.manifestCodesInput && formData.manifestCodesInput.trim()) {
            var manifestCodesInput = formData.manifestCodesInput + "";
            manifestCodesInput = manifestCodesInput.split("\n").join(",");
            manifestCodesInput = manifestCodesInput.split(" ").join(",");
            manifestCodesInputArray = manifestCodesInput.split(",");
            manifestCodesInputArray = manifestCodesInputArray.map(w => w?.trim());
            if (manifestCodesInputArray.length > 10) {
                return notifyApiError("Maximum 10 manifests!");
            }
            queryParams["manifestCodes"] = manifestCodesInputArray.join(",");
        }

        let requestParams = {
            "queryParams": queryParams,
            "headers": {
                "token": config?.lmRearchExternalApiKey
            }
        }

        triggerBatchingApi(requestParams, false)
            .then((resp) => {
                if (resp?.response) {
                    if (resp?.response.indexOf('Booking is already present for location') > -1) {
                        notifyApiError('Batching already done for today. Contact support to initiate again.')
                    }
                    else {
                        notifyApiSuccess('Batching process completed successfully.')
                        window.open(resp.response, "_blank");
                    }
                    const currentDate = new Date()
                    currentDate.setHours(0, 0, 0, 0)
                    const epochTime = currentDate.getTime() / 1000;
                    localStorage.setItem('DrsBatchProcessedAt', epochTime)
                } else {
                    notifyApiError('Batching report not generated.Please contact support')
                    setFormData(formData)
                }
                setLoadingData(false)
                closeModal('success')
                updateBatchingStatus("Start Batching")
            })
            .catch((e) => {
                notifyApiError('Batching process with the following error -> ' + (e?.errors ? e.errors : e))
                setLoadingData(false)
                setFormData(formData)
                updateBatchingStatus("Start Batching")
            })

    }

    const handleSubmitDebounce = useCallback(
        debounce((formData) => handleOnModalConfirmation({...formData, manifestCodesInput : document.getElementById("manifestCodesInput").value}), 1000), []
    )

    return (
        <>

            {/* <Modal
                visible={true}
                title="Start Batching"
                okText={"Submit"}
                okButtonProps={{
                    className: "lsn-btn-primary",
                    type: "default",
                    disabled: loadingData
                }}
                cancelText="Cancel"
                cancelButtonProps={{
                    className: "lsn-btn-secondary",
                    type: "default",
                    disabled: loadingData
                }}
                onCancel={_ => handleCancel()}
                onOk={() => {
                    if (formRef?.current) {
                        formRef.current.formReference.current.submit()
                    }
                }}
                centered={true} destroyOnClose={true}
                width={"30%"}
                closable={false}
            >
                <Spin spinning={loadingData}>
                    <FormWrapper ref={formRef} schema={schema} uiSchema={uiSchema} errorList={errorList}
                        validate={validate} widgets={FormWidgets} onSubmit={handleSubmit}
                        formData={formData} hideSubmit={true}
                    />
                </Spin>
            </Modal > */}
            
            <Modal visible={true} title={'Confirm'}
             okText={'Yes'} cancelText={'No'}
             okButtonProps={{
                className: "lsn-btn-primary",
                type: "default",
                disabled: loadingData
             }}
             cancelButtonProps={{
                className: "lsn-btn-secondary",
                type: "default",
                disabled: loadingData
             }}
             onOk={_ => {
                setLoadingData(true);
                handleSubmitDebounce(formData)
             }}
             onCancel={_ => {
                setLoadingData(false);
                handleCancel();
             }}>
                <div className="flex-column flex-gap-l spacer-s">
                    <div className="flex-box flex-gap-l align-items-center">
                        <div className="flex-1"> Manifest Codes </div>
                        <TextArea className="flex-2" placeholder="Enter Manifest codes comma or new line separated" id="manifestCodesInput" />
                    </div>
                    {
                        !loadingData ?
                        `Are you sure you want to start batching ${formData?.excludePincodes ? "excluding pincodes " + formData.excludePincodes : ""}?` :
                        `Batching in progress...`
                    }
                </div>
            </Modal>
        </>
    )

}

const mapDispatchToProps = dispatch => ({

    updateBatchingStatus: data => dispatch(updateBatchingStatus(data))

})

export default connect(null, mapDispatchToProps)(BatchingModal);
