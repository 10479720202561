import React, { Component } from 'react';
import RootObject from '../RootObject/RootObject';
import RootObjectPreview from '../RootObject/RootObjectPreview';
import './Editor.css';
import { Row, Col, Button, Icon, Input } from 'antd';
import ReactJson from 'react-json-view'
const { TextArea } = Input;
class Editor extends Component {

    state = {
        data: this.props.data.json,
        value: this.props.data.json,
        codeView: true,
        textAreaData: JSON.stringify(this.props.data.json, null, 2)
    }

    componentDidMount() {
        const subscriber = this.props.data.getListener();
        subscriber.on('update', (newData) => {

            this.setState({ data: newData.json, value: newData.json })
        })
    }

    download = () => {
        let filename = "export.json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(this.state.data)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(this.state.data));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    updateConfig = (data, mode) => {
        if(mode === "ui") {
            this.props.updateNewConfig(data)
        } else {
            if(this.props.isValidJson(data)) {
                this.props.updateNewConfig(JSON.parse(data))
            }
            
        }
        
    }

    goBack = () => {
        this.props.history.goBack()
    }

    changeView = () => {
        if (this.props.isValidJson(this.state.textAreaData)) {
            if (this.state.codeView) {

                this.props.updateUIChanges(this.state.textAreaData);
            }

            this.setState(prevState => ({
                codeView: !prevState.codeView,
                textAreaData: JSON.stringify(this.props.data.json, null, 2)
            }));
        }

    }

    handleChange = e => {
        this.setState({ textAreaData: e.target.value })
    }

    render() {
        const { codeView } = this.state;
        return (
            <div className='tree'>
                <div className="title">
                    <h3><Icon onClick={this.goBack} style={{ bottom: 10, float: 'left' }} type="arrow-left" /> Custom Config</h3>
                    <hr />
                </div>
                <Row>
                    {codeView ?
                        <Col span={15} style={{ height: 510, overflow: 'scroll' }}>
                            <TextArea className="textarea-ui" onChange={this.handleChange} rows={20} defaultValue={JSON.stringify(this.state.data, null, 2)} />
                        </Col> :
                        <Col span={15} style={{ height: 510, overflow: 'scroll' }}>
                            <RootObject data={this.state.value} />
                        </Col>
                    }
                    {/* <Col span={7} offset={2}>
                        <a className="ui-text" onClick={this.changeView}>{codeView ? 'UI View' : 'Code View'}</a>
                    </Col> */}

                </Row>
                <Row>
                    {!codeView ?
                        <Col span={24}>
                            <div style={{ float: 'right' }}>
                                <Button loading={this.props.loading} onClick={this.download} type="primary">Download</Button>
                                <Button loading={this.props.loading} onClick={() => this.updateConfig(this.state.data, "ui")} type="primary">Update</Button>
                            </div>

                        </Col>
                        :
                        <Col span={24}>
                            <div style={{ float: 'right' }}>
                                <Button loading={this.props.loading} onClick={this.download} type="primary">Download</Button>
                                <Button loading={this.props.loading} onClick={() => this.updateConfig(this.state.textAreaData, "text")} type="primary">Update</Button>
                            </div>

                        </Col>
                    }
                </Row>





            </div>
        )
    }
}

export default Editor;