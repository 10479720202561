import { Icon, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import ExpandedView from "./expanded.view";

export default function DrsDashboardView({ drsData, noLiveDrs, onPageChange, selectedTags, selectedCustomers }) {

    const expandedRowRender = (record, index, indent, expanded) => {
        return <ExpandedView record={record} index={index} indent={indent}
         expanded={expanded} selectedTags={selectedTags} selectedCustomers={selectedCustomers} />
    }

    useEffect(() => {
        SetExpandedRowKeys([]);
    }, [ drsData ])

    const openRow = record => SetExpandedRowKeys([ record.drsId ])

    const [ expandedRowKeys, SetExpandedRowKeys ] = useState([]);
    
    const columns = [
        {
            title     : "FE Name",
            dataIndex : "feName",
            render    : (data, row) => {
                return (
                    <div className="flex-column flex-gap-xs">
                        <div className="text-semi-bold"> {data} </div>
                        <div className="light-grey-text"> {row.feNumber} </div>
                    </div>
                )
            }
        },
        {
            title : "Location",
            dataIndex : "locationName"
        },
        {
            title : "Assigned",
            dataIndex : "assignedMetaData.count"
        },
        {
            title : "Attempted",
            dataIndex : "attemptedMetaData.count",
            render : (data, row) => {
                return <div>{data} ({row.attemptedMetaData?.percentage}%)</div>
            }
        },
        {
            title : "Suspicious",
            dataIndex : "suspiciousMetaData.count"
        },
        {
            title : "Delivered",
            dataIndex : "deliveredMetaData.count",
            render : (data, row) => {
                return <div>{data} ({row.deliveredMetaData?.percentage}%)</div>
            }
        }
    ]

    return (

        <div className="spacer-hm">
            {
                drsData === undefined ?
                <div className="flex-box justify-content-center align-items-center">
                    <Spin spinning={true} />
                </div> :
                noLiveDrs ?
                <Table columns={columns} dataSource={[]}
                locale={{
                    emptyText : "No Live DRS at this moment."
                }}/> :
                <Table columns={columns} bordered={false}
                 dataSource={drsData.response} size="small"
                 rowKey="drsId" expandedRowKeys={expandedRowKeys}
                 expandedRowRender={(record, index, indent, expanded) => {

                    if(record.assignedMetaData && record.assignedMetaData.count > 0)
                        return expandedRowRender(record, index, indent, expanded)

                    return undefined;
                 }}
                 expandIcon={props => {
                    return (
                        <Icon onClick={_ => {
                            if (props.expanded) {
                                SetExpandedRowKeys([])
                            }
                            else {
                                openRow(props.record)
                            }
                        }}
                        type="caret-right"
                        style={{
                            transform  : props.expanded ? "rotate(90deg)" : "none",
                            transition : "all 0.25s ease-in-out"
                        }} />
                    )
                 }}
                 pagination={{
                    total : drsData?.totalCount,
                    current : drsData?.currentPageNo,
                    pageSize : drsData?.currentPageSize,
                    onChange : onPageChange
                 }} />

            }
        </div>
    )
}